import React from "react"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import track from "../../../components/track"
import heroBurden from "../../../images/hero-hcp-burden.png"
import heroBurdenLarge from "../../../images/hero-hcp-burden-large.png"
import heroBurdenMobile from "../../../images/hero-hcp-burden-mobile.png"
import diagramOne from "../../../images/diagram-80-2@2x.png"
import diagramTwo from "../../../images/diagram-71-2@2x.png"

const Burden = () => {

  const pageTitle = 'Burden of OIC';
  const pageDescription = 'Find out how MOVANTIK is specifically designed to treat opioid-induced constipation with its mechanism of action (MOA).';
  const pageName = "Burden of OIC";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="hcp" pageSection="hcp-burden-of-oic" pageClass="burden-of-oic" pageName={pageName}>
      <div className="page-title">
        <div className="container">Burden of OIC</div>
      </div>
      <div className="hero">
        <img src={heroBurdenLarge} className="hero-img large" alt="" />
        <img src={heroBurden} className="hero-img medium" alt="" />
        <img src={heroBurdenMobile} className="hero-img mobile" alt="" />
        <div className="container">
          <div className="headlines">
            <h1>OIC is prevalent—yet undertreated.</h1>
          </div>
        </div>
      </div>
      <div className="container">
      <h3>Patients don’t always open up about the negative impact of their constipation<sup>1,</sup>*</h3>
        <div className="columns two">
          <div className="column left-column">
            <img src={diagramOne} className="chart" alt="chart" />
          </div>
          <div className="column right-column">
            <img src={diagramTwo} className="chart" alt="chart" />
          </div>
        </div>
        <div className="end-matter">
          <div className="footnotes">
          <div className="footnote asterisk">*In an analysis of a multinational longitudinal study of 493 patients with chronic non-cancer pain and clinician-identified, patient-confirmed OIC.<sup>1</sup></div>
            <div className="footnote"><sup>&dagger;</sup>Constipation treatments included OTC laxatives (stool softeners, osmotics, stimulants, salines, and rectal options), prescription laxatives, and behavioral therapies (fiber supplements, increased fluids and exercise, and dietary changes). 29% of patients in the study reported “much benefit” from constipation treatments.<sup>1</sup></div>
            
          </div>
          <div className="center">
          <Link to="/hcp/efficacy/" id="hcp-about1-btn-am" className="button" onClick={() => track("HCP Burden of OIC page", "button click", "HCP About-Relieve the burden Button")}>
            <div className="btn-text">Explore the possibility of<br /> <span className="bold">same day relief</span></div>
            <div className="btn-arrow"></div>
          </Link>
        </div>
        <div className="references"><span className="bold">References: 1.</span> Coyne KS, LoCasale RJ, Datto CJ, Sexton CC, Yeomans K, Tack J. Opioid-induced constipation in patients with chronic noncancer pain in the USA, Canada, Germany, and the UK: descriptive analysis of baseline patient-reported outcomes and retrospective chart review. <em>Clinicoecon Outcomes Res.</em> 2014;6:269-281. <span className="bold">2.</span> Bell TJ, Panchal SJ, Miaskowski C, et al. The prevalence, severity, and impact of opioid-induced bowel dysfunction: results of a US and European Patient Survey (PROBE 1). <em>Pain Med.</em> 2009;10:35-42. <span className="bold">3.</span> Abramowitz L, Beziaud N, Labreze L. Prevalence and impact of constipation and bowel dysfunction induced by strong opioids: a cross-sectional survey of 520 patients with cancer pain: DYONISOS study. <em>J Med Econ.</em> 2013;16(2):1423-1433. <span className="bold">4.</span> Kalso E, Edwards JE, Moore RA, et al. Opioids in chronic non-cancer pain: systematic review of efficacy and safety. <em>Pain</em>. 2004;112:372-380. <span className="bold">5.</span> Crockett SD, Greer KB, Heidelbaugh JJ, et al. American Gastroenterological Association Institute Clinical Guidelines Committee. <em>Gastroenterology.</em> 2019;156:218-226.</div>
        </div>
      </div>
    </Layout>
  )
}

export default Burden
